import React, { Component } from "react";

import Table from "react-bootstrap/Table";

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

export default class Overview extends Component {

    static myPageNumber = 1;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const data = this.props.data.get("overview");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const table = [];

        data.optional.forEach((value, desc) => table.push(
            <tr key={desc}>
                <td><b>{desc}</b></td>
                <td>{value}</td>
            </tr>
            )
        );

        return (
            <>
                <h1>Hallo, { data.username }!</h1>
                <p className={"info-text"}>
                    Zunächst der nicht so überraschende Teil: Wie jedes Internetunternehmen hat Spotify einige Daten
                    über dich, welche du dem Unternehmen freiwillig und wissentlich gegeben hast. Dazu zählen
                    natürlich die Daten bei Erstellung des Accounts, Bezahlmethoden und so weiter. Eine kleine Auswahl
                    dieser Daten findest du auf dieser Seite. Erinnerst du dich noch, wann du Spotify diese Daten
                    gegeben hast?
                </p>
                <Table>
                    <thead></thead>
                    <tbody>
                        { table }
                    </tbody>
                </Table>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Andere Datei hochladen"
                    pageSelector={ this.props.pageSelector }
                    prev="upload"
                    next={ Overview.myPageNumber + 1 }
                />
            </>
        );
    }
}
