import React, { Component } from "react";

import "./Weclome.css"
import Navigator from "../components/Navigator";

export default class End extends Component {

    static myPageNumber = 10;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <div className={"welcome-page"}>
                <h1>Geschafft!</h1>
                <h3>War das alles?</h3>
                <p className={"info-text"}>
                    Auf dieser Seite wurden nur die üblichen Einträge deiner Daten analysiert, in den Daten gibt es
                    aber mehr zu entdecken. Wenn du dich dafür Interessiert bietet es sich an, mal selber einen Blick
                    dort rein zu werfen.
                    <br />
                    Denk immer daran: Die Daten, welche du über die Selbstauskunftsanfrage auf der Webseite von Spotify
                    erhältst, sind bei weitem nicht vollständig. Spotify hat nicht nur Daten, die weiter in die
                    Vergangenheit zurückreichen, sondern in vielen Bereichen auch noch detaillierte Daten, als sie
                    in dem Download enthalten sind. Nach der DSGVO steht dir auch zu, einen Blick in diese Daten zu
                    werfen, jedoch musst du dafür Spotify eine Mail schreiben und relativ lange warten. Eine Anleitung,
                    wie man die vollständigen Daten erhält, findest
                    du <a href="https://support.spotify.com/de/article/understanding-my-data/" target="_blank" rel="noreferrer">
                        hier
                    </a> im Abschnitt "Zusätzliche Daten". Probier es aus, es lohnt sich.
                </p>

                <h3>Gruselig?</h3>
                <p className={"info-text"}>
                    Du bist überrascht, was man so alles aus deinen Nutzerdaten herausfinden kann? Dann solltest du mal
                    einen Blick in deine Spotify Datenschutz- und Privatsphäreeinstellungen werfen, hier eine kleine
                    Anleitung, wie die diese Einstellungen optimal wählst:
                </p>
                <ol>
                    <li>
                        <b>Facebookdaten nicht verarbeiten:</b> In den <a href="https://www.spotify.com/de/account/privacy/" target="_blank" rel="noreferrer">Datenschutzeinstellungen</a> deines
                        Profils solltest du die Option "Meine Facebook-Daten verarbeiten" deaktivieren.
                    </li>
                    <li>
                        <b>Individuelle Werbeinhalte deaktivieren:</b> Auf <a href="https://www.spotify.com/de/account/privacy/" target="_blank" rel="noreferrer">derselben Seite</a> solltest
                        du die Option "Meine personenbezogenen Daten für individuell angepasste Werbung verarbeiten" deaktivieren.
                    </li>
                    <li>
                        <b>Weniger Daten mit Fremden teilen:</b> In den Spotify-Apps solltest du im Einstellungsmenü
                        unter "Social" ein paar Einstellungen finden: Wir empfehlen die Einstellungen "Höraktivität" und
                        "Kürzlich gehörte Künstler*innen" zu deaktivieren.
                    </li>
                    <li>
                        <b>Cookies in der Desktop-App deaktivieren:</b> In der Desktop-App im Einstellungsmenü findest
                        du ganz unten einen Bereich "Datenschutz". Aktiviere diese Einstellung, um Cookies in der App zu
                        deaktivieren und starte anschließend die Spotify-App neu.
                    </li>
                </ol>
                <Navigator
                    nextToolTip="Zurück zur Startseite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ End.myPageNumber - 1 }
                    next={ "welcome" }
                    restart
                />
            </div>
        );
    }
}
