import React, { Component } from "react";

import Highcharts from 'highcharts';

import AlertDismissible from "../components/AlertDismissible";
import LoadingChart from "../components/LoadingChart";
import Navigator from "../components/Navigator";
import Accordion from "react-bootstrap/Accordion";

require("highcharts/modules/heatmap")(Highcharts);
require("highcharts/modules/data")(Highcharts);

export default class HeatMap extends Component {

    static myPageNumber = 7;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const data = this.props.data.get("streamingHistory");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        let csv = data.heatmap.csv;

        const heatMapOptions = {
            data: {
                csv: csv,
                itemDelimiter: ',',
                lineDelimiter: '\n'
            },
            chart: {
                type: 'heatmap',
                backgroundColor: 'transparent',
                height: '50%',
                plotBackgroundColor: '#0F7935',
                zoomType: 'x'
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: {
                type: 'datetime',
                min: data.heatmap.min.getTime(),
                max: data.heatmap.max.getTime(),
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    },
                    align: 'left',
                    x: 5,
                    y: 14,
                    format: '{value:%d.%m}' // day.month
                },
                showLastLabel: false,
                tickLength: 16
            },

            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    format: '{value}:00',
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    },
                },
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: true,
                tickPositions: [2, 5, 8, 11, 14, 17, 20, 23],
                tickWidth: 1,
                min: 0,
                max: 23,
                reversed: true
            },
            colorAxis: {
                stops: [
                    [0, '#0F7935'],
                    [0.24, '#E6E02F'],
                    [0.91, '#B91D1D'],
                    [1, '#B91D1D']
                ],
                min: 0,
                max: 60,
                startOnTick: false,
                endOnTick: false,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    },
                    format: '{value}'
                },
            },
            series: [{
                boostThreshold: 100,
                borderWidth: 0,
                colsize: 24 * 36e5, // one day
                tooltip: {
                    headerFormat: 'Wiedergabeminuten<br/>',
                    pointFormat: '{point.x:%e %b, %Y} {point.y}:00: <b>{point.value}min</b>'
                },
                turboThreshold: Number.MAX_VALUE // #3404, remove after 4.0.5 release
            }]
        };

        return (
            <>
                <h1>Wann hörst du Musik?</h1>
                <p className={"info-text"}>
                    Wenn man diese zunächst unscheinbaren Daten zusammenführt und noch einmal ganz anders darstellt,
                    zum Beispiel in einer Heatmap, kann man eine ganze Menge über dich erfahren. Versuche zum Beispiel
                    zu erkennen, wann du schläfst, wann du zur Schule/Uni/Arbeit gehst, wann du feiern warst oder wann
                    du im Urlaub warst. Besonders interessant ist auch, wie sich dein Nutzungsverhalten - und somit
                    häufig auch dein Leben - über die Zeit verändert.
                </p>
                <p className={"info-text"}>
                    Du kannst mit der Maus einen Bereich in der Heatmap auswählen, um an diesen heranzuzoomen und so mehr
                    Details zu sehen. Um die Auswahl zurückzusetzen findest du dann oben rechts in der Heatmap einen Knopf.
                </p>
                <LoadingChart
                    name={"Heatmap"}
                    highcharts={Highcharts}
                    options={heatMapOptions}
                />
                <br />
                <Accordion className={"info-accordion"} defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b>Was kann Spotify mit diesen Daten anfangen?</b></Accordion.Header>
                        <Accordion.Body>
                            Mithilfe automatisierter Tools lässt sich leicht feststellen, zu welchen Uhrzeiten du schläfst oder an welchen Tagen du feiern warst. 
                            Spotify kann diese Daten zur Ermittlung deiner Persönlichkeit verwenden - und damit seinen Werbepartnern noch zuverlässigere
                            Targeting-Optionen bieten. Bereits in der Vergangenheit veröffentlichte Spotify eine <a href="https://research.atspotify.com/just-the-way-you-are-music-listening-and-personality/" target="_blank" rel="noreferrer">Studie</a>,
                            in der unter anderem analysiert wurde, wie Spotify-Nutzer Musik hören und wie sich dies auf deren Perönlichkeit auswirkt.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b>Beispiel Demodatensatz</b></Accordion.Header>
                        <Accordion.Body>
                            Es darf nicht vergessen werden, dass Spotify nicht nur weiß, <b>wann</b> ein Stream abgespielt wurde, sondern durch die 
                            IP-Adresse auch (grob) <b>wo</b> ein Song abgespielt wurde.
                            <br />
                            Somit lässt sich beispielsweise herausfinden, an welchen Tagen du in den Urlaub gefahren bist:
                            <img src="/heatmap-explanation.png" alt="Heatmap Erklärung" width="100%" />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ HeatMap.myPageNumber - 1 }
                    next={ HeatMap.myPageNumber + 1 }
                />
            </>
        );
    }
}
