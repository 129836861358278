import * as zip from "@zip.js/zip.js";

export default class GenericAnalyzer {
    fileMap;

    constructor(fileMap) {
        this.fileMap = fileMap;
    }

    isFileInMap = (fileName) => {
        return this.fileMap.has(fileName);
    }


    readFileFromZipEntry = async (fileName) => {
        if (!this.isFileInMap(fileName)) {
            console.error("Unable to find file " + fileName);
            return null;
        }

        let file = this.fileMap.get(fileName);

        let result;
        try {
            result = await file.getData(new zip.TextWriter());
        } catch (e) {
            console.error(e);
            return null;
        }

        if (result == null) {
            console.error("#readFileFromZipEntry result was null!");
            return null;
        }

        return result;
    }

    readJSONFromZipEntry = async (fileName) => {
        let result = await this.readFileFromZipEntry(fileName);
        if (result == null) return null;

        let json;
        try {
            json = await JSON.parse(result);
        } catch (e) {
            console.error(e);
            return null;
        }

        return json;
    }
}