import React, { Component } from "react";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

export default class SearchTime extends Component {

    static myPageNumber = 9;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const data = this.props.data.get("searchQueries");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const hourOfDayOptions = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            title: null,
            xAxis: {
                categories: new Array(24).fill(0).map((_, i) => `${i}:00 - ${i + 1}:00`),
                crosshair: false,
                style: {
                    color: '#FFFFFF',
                    fontSize: 14,
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Anzahl der Suchen',
                color: '#F15C80',
                data: data.searchByHour

            }],
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        const byDayOptions = {
            title: null,
            chart: {
                backgroundColor: 'transparent',
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            series: [{
                name: 'Anzahl der Suchen',
                data: data.searchByDay,
                color: '#60B4EC',
            }],
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        return (
            <>
                <h1>Wann suchst du?</h1>
                <p className={"info-text"}>
                    Interessant ist natürlich auch, wann du Suchanfragen stellst. Hier siehst du die Anzahl der
                    Suchanfragen je Stunde:
                </p>
                <div className={"chartarea"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={hourOfDayOptions}
                    />
                </div>
                <p className={"info-text"}>
                    Ebenfalls spannend ist die Zeitachse der Suchanfragen. Hier siehst du, wie viel du an welchem Tag
                    bei Spotify gesucht hast. Fällt dir auf, wann du das letzte mal feiern warst?
                </p>
                <div className={"chartarea"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={byDayOptions}
                    />
                </div>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ SearchTime.myPageNumber - 1 }
                    next={ SearchTime.myPageNumber + 1 }
                />
            </>
        );
    }
}
