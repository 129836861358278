import React, { Component } from "react";

import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

export default class ArtistOverview extends Component {

    static myPageNumber = 3;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        const data = this.props.data.get("streamingHistory");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const artistTable = [];
        for (let [hour, entry] of data.topartistsperhour) {
            let hourList = [];
            let iterator = entry.entries();
            for (let i = 0; i < Math.min(3, entry.size); i++) {
                let temp = iterator.next();

                hourList.push(<span key={i + "-" + hour + "-artists"}>{i + 1}. {temp.value[0]} ({temp.value[1]} Streams)</span>);
                if (i !== 2) {
                    hourList.push(<br key={i + "-" + hour + "-artists-br"} />);
                }
            }

            artistTable.push(
                <tr key={"artists" + hour}>
                    <td><b>{ hour } Uhr bis { hour + 1 } Uhr</b></td>
                    <td>{ hourList }</td>
                </tr>
            );
        }

        const titleTable = [];
        for (let [hour, entry] of data.toptitlesperhour) {
            let hourList = [];
            let iterator = entry.entries();
            for (let i = 0; i < Math.min(3, entry.size); i++) {
                let temp = iterator.next();

                hourList.push(<span key={i + "-" + hour + "-title"}>{i + 1}. {temp.value[0]} ({temp.value[1]} Streams)</span>);
                if (i !== 2) {
                    hourList.push(<br key={i + "-" + hour + "-title-br"} />);
                }
            }

            titleTable.push(
                <tr key={"titles" + hour}>
                    <td><b>{ hour } Uhr bis { hour + 1 } Uhr</b></td>
                    <td>{ hourList }</td>
                </tr>
            );
        }

        return (
            <>
                <h1>Wann hörst du eigentlich welche Musik?</h1>
                <p className={"info-text"}>
                    Spotify speichert deinen genauen Streamingverlauf. Dabei wird nicht nur gespeichert, wann du welchen
                    Song gestartet hast, sondern auch ob Autoplay aktiviert war, wie lange der Song abgespielt wurde,
                    wie es zum Wiedergabeende des Titels kam und so weiter. Leider stellt Spotify diese Daten nicht
                    alle in der herunterladbaren Datenschutz-Selbstauskunft zur Verfügung. Auch ist nicht der
                    ganze Streamingverlauf enthalten, sondern nur das letzte Jahr. Trotzdem kann man mit den Daten
                    schon viel anfangen.
                </p>
                <p className={"info-text"}>
                    Welche Musik oder welchen Künstler man zu welcher Tageszeit hört kann sehr viel über einen aussagen.
                    Hier zum Beispiel einmal die Top 3 Künstler, die du in der jeweiligen Stunde gehört hast:
                </p>
                <Row className="justify-content-md-center">
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { artistTable.slice(0, 8) }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { artistTable.slice(8, 16) }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { artistTable.slice(16, 24) }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <p className={"info-text"}>
                    Noch mehr Aussagekraft als die Künstler haben die Titel, die du zu bestimmten Uhrzeiten hörst. Daher
                    findest du in dieser Tabelle die Top 3 Titel, die du in den jeweiligen Stunden gehört hast:
                </p>
                <Row className="justify-content-md-center">
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { titleTable.slice(0, 8) }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { titleTable.slice(8, 16) }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={"auto"}>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { titleTable.slice(16, 24) }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ ArtistOverview.myPageNumber - 1 }
                    next={ ArtistOverview.myPageNumber + 1 }
                />
            </>
        );
    }
}
