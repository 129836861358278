import GenericAnalyzer from "./GenericAnalyzer";

export default class UserDataAnalyzer extends GenericAnalyzer {
    readData = async () => {
        let json = await this.readJSONFromZipEntry("Userdata.json");
        if (json == null || json.username === undefined) return null;

        let result = {
            optional: new Map(),
            username: json.username
        }

        const userdataDetail = [
            { key: "email", desc: "E-Mail Adresse", type: "string" },
            { key: "country", desc: "Herkunftsland", type: "string" },
            { key: "createdFromFacebook", desc: "Facebook Account verknüpft", type: "bool" },
            { key: "birthdate", desc: "Geburtsdatum", type: "date" },
            { key: "postalCode", desc: "Postleitzahl", type: "string" },
            { key: "creationTime", desc: "Auf Spotify seit", type: "date" },
            { key: "gender", desc: "Geschlecht", type: "string" },
            { key: "mobileNumber", desc: "Mobiltelefonnummer", type: "string" },
            { key: "mobileOperator", desc: "Mobilfunkbetreiber", type: "string" },
            { key: "mobileBrand", desc: "Handyhersteller", type: "string" }
        ]

        for (let scope of userdataDetail) {
            if (json[scope.key] === undefined || json[scope.key] === null) continue;
            switch (scope.type) {
                case "bool":
                    result.optional.set(scope.desc, json[scope.key] ? "Ja" : "Nein");
                    break;
                case "date":
                    let date = new Date(json[scope.key]);
                    result.optional.set(scope.desc, date.toLocaleDateString('de-DE', {
                        weekday:"long",
                        year:"numeric",
                        month:"short",
                        day:"numeric"
                    }));
                    break;
                default:
                    result.optional.set(scope.desc, json[scope.key]);
            }
        }

        if (this.isFileInMap("FamilyPlan.json")) {
            let family = await this.readJSONFromZipEntry("FamilyPlan.json");
            if (family != null && family.address !== undefined) {
                let address = family["address"];
                result.optional.set("Familienplan Details",
                    `${address.real_name}, ${address.street}, ${address.postal_code} ${address.city}`);
            }
        }

        if (this.isFileInMap("Follow.json")) {
            let follow = await this.readJSONFromZipEntry("Follow.json");
            if (follow != null) {
                if (follow.followerCount !== undefined) {
                    result.optional.set("Anzahl Personen, die dir folgen", follow.followerCount);
                }

                if (follow.followingUsersCount !== undefined) {
                    result.optional.set("Anzahl Personen, denen du folgst", follow.followingUsersCount);
                }

                if (follow.dismissingUsersCount !== undefined) {
                    result.optional.set("Anzahl gelöschter Follower", follow.dismissingUsersCount);
                }
            }
        }

        if (this.isFileInMap("Payments.json")) {
            let payments = await this.readJSONFromZipEntry("Payments.json");
            if (payments != null && payments.payment_method !== undefined) {
                result.optional.set("Bezahloption", payments.payment_method);

                if (payments.creation_date !== undefined) {
                    result.optional.set("Bezahloption seit",
                        new Date(payments.creation_date).toLocaleDateString('de-DE', {
                            weekday:"long",
                            year:"numeric",
                            month:"short",
                            day:"numeric"
                        })
                    );
                }
            }
        }

        return result;
    }
}