import GenericAnalyzer from "./GenericAnalyzer";

export default class SearchQueriesAnalyzer extends GenericAnalyzer {
    readData = async () => {
        if (!this.isFileInMap("SearchQueries.json")) return [];


        let json = await this.readJSONFromZipEntry("SearchQueries.json");
        if (json == null) return null;

        if (json.length === undefined || json.length === 0) return [];

        let tagsQuery = new Map();
        let tagsInteractions = new Map();
        let searchHour = new Array(24).fill(0);
        let searchByDay = new Map();

        for (let entry of json) {
            let lastValQuery = tagsQuery.get(entry.searchQuery);
            if (lastValQuery === undefined) {
                tagsQuery.set(entry.searchQuery, 1);
            } else {
                tagsQuery.set(entry.searchQuery, lastValQuery + 1);
            }

            if (entry.searchInteractionURIs.length !== 0) {
                let lastValInteraction = tagsInteractions.get(entry.searchQuery);

                if (lastValInteraction === undefined) {
                    tagsInteractions.set(entry.searchQuery, entry.searchInteractionURIs.length);
                } else {
                    tagsInteractions.set(entry.searchQuery, entry.searchInteractionURIs.length + lastValInteraction);
                }
            }

            let searchDate = new Date(entry.searchTime.replace("[UTC]", ""));
            searchHour[searchDate.getHours()]++;

            let searchDay = searchDate.toISOString().slice(0,10);
            let lastVal = searchByDay.get(searchDay);
            if (lastVal !== undefined) {
                searchByDay.set(searchDay, lastVal + 1);
            } else  {
                searchByDay.set(searchDay, 1);
            }
        }

        let searchByDayArray = [];
        searchByDay = new Map([...searchByDay.entries()]
            .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()));
        for (let [at, searches] of searchByDay.entries()) {
            searchByDayArray.push([new Date(at).getTime(), searches]);
        }

        return {
            queries: Array.from(tagsQuery).map(([key, value]) => ({ name: key, weight: value })),
            interaction: Array.from(tagsInteractions).map(([key, value]) => ({ name: key, weight: value })),
            searchByHour: searchHour,
            searchByDay: searchByDayArray
        };
    }
}