import React, { Component } from "react";

import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

export default class Favorites extends Component {

    static myPageNumber = 4;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        const data = this.props.data.get("streamingHistory");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const topTitleTable = [];
        let iterator = data.toptitles.entries();
        for (let i = 1; i <= 20; i++) {
            let temp = iterator.next();

            topTitleTable.push(
                <tr key={"toptitle" + i}>
                    <td><b>{i}.</b></td>
                    <td>{temp.value[0]} ({temp.value[1]} Streams)</td>
                </tr>
            );
        }

        const topArtistTable = [];
        iterator = data.topartists.entries();
        for (let i = 1; i <= 20; i++) {
            let temp = iterator.next();

            topArtistTable.push(
                <tr key={"topartists" + i}>
                    <td><b>{i}.</b></td>
                    <td>{temp.value[0]} ({temp.value[1]} Streams)</td>
                </tr>
            );
        }

        return (
            <>
                <h1>Deine Lieblingskünstler und -Titel</h1>
                <p className={"info-text"}>
                    Nicht sonderlich relevant für die Frage, was Spotify über dich weiß, aber dafür trotzdem sicherlich
                    spannend: Hier findest du einmal deine Top 20 Künstler und Titel aus dem Streamingverlauf des
                    vergangenen Jahres.
                </p>
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <h5>Top 20 Titel</h5>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { topTitleTable }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={5}>
                        <h5>Top 20 Künstler</h5>
                        <Table>
                            <thead></thead>
                            <tbody>
                            { topArtistTable }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ Favorites.myPageNumber - 1 }
                    next={ Favorites.myPageNumber + 1 }
                />
            </>
        );
    }
}
