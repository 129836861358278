import React, { PureComponent } from "react";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";

import Welcome from "./pages/Welcome";
import Upload from "./pages/Upload";
import Overview from "./pages/Overview";
import Inferences from "./pages/Inferences";
import ArtistOverview from "./pages/ArtistOverview";
import Favorites from "./pages/Favorites";
import HeatMap from "./pages/HeatMap";
import HistoryGraphs from "./pages/HistoryGraphs";
import SearchTagCloud from "./pages/SearchTagCloud";
import SearchTime from "./pages/SearchTime";
import FavoritesGraphs from "./pages/FavoritesGraphs";
import End from "./pages/End";

export default class App extends PureComponent {
    pageData = null;

    constructor(props) {
        super(props);

        this.state = {
            page: "welcome"
        }
    }

    setActivePage = (pageName) => {
        this.setState({
            page: pageName
        });
    }

    submitData = (newData) => {
        this.pageData = newData;
    }

    render() {
        return (
        <React.StrictMode>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand>
                        Was weiß Spotify über dich?
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <Badge bg={"success"}>Seite { isNaN(this.state.page) ? 0 : this.state.page }/10</Badge>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                {
                    {
                        'welcome': <Welcome         pageSelector={this.setActivePage} />,
                        'upload':  <Upload          pageSelector={this.setActivePage} submitData={this.submitData} />,
                        '1':       <Overview        pageSelector={this.setActivePage} data={this.pageData} />,
                        '2':       <Inferences      pageSelector={this.setActivePage} data={this.pageData} />,
                        '3':       <ArtistOverview  pageSelector={this.setActivePage} data={this.pageData} />,
                        '4':       <Favorites       pageSelector={this.setActivePage} data={this.pageData} />,
                        '5':       <FavoritesGraphs pageSelector={this.setActivePage} data={this.pageData} />,
                        '6':       <HistoryGraphs   pageSelector={this.setActivePage} data={this.pageData} />,
                        '7':       <HeatMap         pageSelector={this.setActivePage} data={this.pageData} />,
                        '8':       <SearchTagCloud  pageSelector={this.setActivePage} data={this.pageData} />,
                        '9':       <SearchTime      pageSelector={this.setActivePage} data={this.pageData} />,
                        '10':      <End             pageSelector={this.setActivePage} />
                    }[this.state.page]
                }
            </Container>
        </React.StrictMode>
        );
    }
}