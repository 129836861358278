import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";

import "./Weclome.css"
import Navigator from "../components/Navigator";

export default class Welcome extends Component {

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <div className={"welcome-page"}>
                <h1>Du wolltest schon immer wissen, was Spotify so alles über dich weiß?</h1>
                <p className={"info-text"}>
                    Nach dem EU-Gesetz DSGVO muss jedes Internetunternehmen dir jederzeit eine vollständige Auskunft
                    über
                    deine gesammelten, persönlichen Daten ermöglichen - das gilt natürlich auch Spotify. Jetzt stellt
                    sich
                    vielleicht die Frage: Was kann Spotify schon über mich wissen? Eine ganze Menge, und genau das
                    wollen
                    wir dir mit dieser Webseite zeigen!
                </p>
                <p className={"info-text"}>
                    Spotify exportiert dir diese Daten in einem Format, was zwar für den Computer, nicht aber für den
                    Menschen gut lesbar ist. Diese Webseite ermöglicht es dir, einen besseren Einblick in diese Daten zu
                    bekommen - mit einigen Randinformationen und Grafiken.
                </p>
                <p className={"info-text"}>
                    Alle Daten, die du hier mit dieser Webseite öffnest, werden nicht an einen Server übertragen - die
                    Daten verbleiben vollständig in deinem Browser und werden nur dort analysiert.
                </p>
                <p className={"info-text"}>
                    Falls du deine Daten noch nicht erhalten hast aber die Webseite jetzt schon ausprobieren möchtest:
                    Auf der nächsten Seite findest du eine Möglichkeit Beispieldaten zu laden.
                </p>
                <hr/>
                <h2>Folge der Anleitung, um deine Spotify Daten analysieren zu lassen:</h2>
                <Container className={"welcome-area"}>
                    <Row>
                        <Col sm={12} md={3}>
                            <div className={"spacer"}>
                                <h5><Badge bg="success">1</Badge> Daten anfordern</h5>
                                <hr/>
                                <p>
                                    Nutze die DSGVO-Selbstauskunft um deine Spotify Daten anzufragen. Nutze dafür
                                    folgenden
                                    Link und folge der Anleitung unten auf der Seite.
                                </p>

                                <Button variant="success" href="https://www.spotify.com/de/account/privacy/"
                                        target="_blank">
                                    Daten anfragen
                                </Button>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className={"spacer"}>
                                <h5><Badge bg="success">2</Badge> Auf Daten warten</h5>
                                <hr/>
                                <p>
                                    Es kann einige Tage dauern, bis du deine Daten erhältst. Meistens bekommst du nach
                                    etwa
                                    drei Tagen eine Mail mit dem Downloadlink für deine Daten. In seltenen Fällen kann
                                    es bis
                                    zu 30 Tagen dauern, bis du deine Daten erhältst.
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className={"spacer"}>
                                <h5><Badge bg="success">3</Badge> Daten öffnen</h5>
                                <hr/>
                                <p>
                                    Öffne die erhaltene Zip-Datei mit dieser Webseite. Dabei bleiben alle deine Daten im
                                    Browser, keine Daten werden an einen Server übermittelt.
                                </p>

                                <Button variant="success" onClick={() => this.props.pageSelector("upload")}>
                                    Daten öffnen
                                </Button>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className={"spacer"}>
                                <h5><Badge bg="success">4</Badge> Ergebnisse anschauen</h5>
                                <hr/>
                                <p>
                                    Nun kannst du dir einige Analysen zu deinen Daten anschauen, natürlich
                                    analysieren wir nicht alles: Wenn du Interesse hast, lohnt es sich, selber
                                    einen Blick in die Daten zu werfen.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={"disclaimer"}>
                    <hr/>
                    <p>
                        <b>Disclaimer: </b>
                        Dieses Projekt wird weder von Spotify AB betrieben, noch steht es in irgendeinem Zusammenhang
                        mit
                        der Spotify AB. Dieses Projekt entstand als Abschlussarbeit in "Marketing Fallstudien" WS
                        2021/2022
                        an der Friedrich Alexander Universität Erlangen-Nürnberg bei Dr. Christan Götz. Der Quellcode
                        dieser
                        Seite ist <a href="https://gitlab.cs.fau.de/es56itog/spotify-dump-analyzer" rel="noreferrer"
                                     target="_blank">
                        hier
                    </a> einsehbar.
                    </p>
                </div>
                <Navigator
                    next="upload"
                    nextToolTip="Datei öffnen"
                    pageSelector={this.props.pageSelector}
                />
            </div>
        );
    }
}
