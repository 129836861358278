import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

require("highcharts/modules/wordcloud")(Highcharts);

export default class SearchTagCloud extends Component {

    static myPageNumber = 8;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const refData = this.props.data.get("searchQueries");

        if (refData == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const data = refData.queries;

        const tagcloudOptions = {
            chart: {
                backgroundColor:'transparent',
                height: "70%"
            },
            title: {
                text: ''
            },
            series: [{
                type: 'wordcloud',
                data,
                minFontSize: 50,
                maxFontSize: 140,
                enableMouseTracking: false,
                spiral: 'archimedean'
            }]
        };

        const interactionsCloudOptions = {
            chart: {
                backgroundColor:'transparent',
                height: "70%"
            },
            title: {
                text: ''
            },
            series: [{
                type: 'wordcloud',
                data: refData.interaction,
                minFontSize: 50,
                maxFontSize: 140,
                enableMouseTracking: false,
                spiral: 'archimedean'
            }]
        };

        return (
            <>
                <h1>Gesucht und Gefunden?</h1>
                <p className={"info-text"}>
                    Spotify speichert auch deine Suchanfragen, nicht nur, damit du einen lokalen Suchverlauf auf deinem
                    Endgerät hast, sondern auch, weil es viel darüber verrät, wofür du dich wann interessierst.
                </p>
                { (data === undefined || data.length < 5) &&
                    <p className={"info-text"}>
                        Bei dir konnten keine oder zu wenig Suchergebnisse gefunden werden, um dazu Analysen anzuzeigen.
                        Bitte gehe zur nächsten Seite weiter.
                    </p>
                }
                { (data !== undefined && data.length >= 5) &&
                    <Row>
                        <Col className={"tagcloud"} md={6}>
                            <p className={"info-text"}>
                                In dieser Wortwolke siehst du, nach welchen Begriffen du gesucht hast. Größere Begriffe
                                wurden häufiger gesucht. Erinnerst du dich noch, wann du danach gesucht hast?
                            </p>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={tagcloudOptions}
                            />
                        </Col>
                        <Col className={"tagcloud"} md={6}>
                            <p className={"info-text"}>
                                Wenn du mit Ergebnissen der Suche interagierst, speichert Spotify das auch. In dieser
                                Wortwolke sind die Suchanfragen, mit dessen Ergebnissen du häufiger interagiert hast,
                                größer dargestellt.
                            </p>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={interactionsCloudOptions}
                            />
                        </Col>
                    </Row>
                }
                <br />
                <Accordion className={"info-accordion"} defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b>Was kann Spotify mit diesen Daten anfangen?</b></Accordion.Header>
                        <Accordion.Body>
                            <b>Beispiel Demodatensatz:</b> <br />
                            Dort hat der Benutzer nach "ESC 2022" gesucht, die Person scheint sich also für den Eurovision Song Contest zu interessieren. 
                            Spotify könnte diesen Rückschluss seinen Werbepartnern zur Verfügung stellen. 
                            Diese können wiederum die passende Werbung auf Spotify schalten: Wie wäre es zum Beispiel mit Tickets für den ESC 2022 in Turin?
                            <br /><br />
                            Hierbei handelt es sich natürlich noch um ein recht harmloses Beispiel. Was ist beispielsweise, wenn der Benutzer nach einem Podcast seines 
                            Lieblingspolitikers sucht? Sicherlich möchte man nicht, dass Spotify die politische Gesinnung seiner Benutzer an Dritte weitergibt...
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ SearchTagCloud.myPageNumber - 1 }
                    next={ SearchTagCloud.myPageNumber + 1 }
                />
            </>
        );
    }
}
