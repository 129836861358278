import React, { PureComponent } from "react";

import Spinner from "react-bootstrap/Spinner";

import HighchartsReact from "highcharts-react-official";

export default class LoadingChart extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            beginRender: false,
            rendered: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ beginRender: true }), 0);
    }

    renderCallback = () => {
        this.setState({rendered: true});
    }

    render() {
        return (
            <>
            { !this.state.rendered &&
                <div className="d-flex justify-content-center loader">
                    <Spinner animation="grow" />
                    <h5>Lade { this.props.name } ... </h5>
                </div>
            }
            { this.state.beginRender &&
                <div className={"chartarea"} hidden={!this.state.rendered}>
                    <HighchartsReact
                        highcharts={this.props.highcharts}
                        options={this.props.options}
                        callback={() => this.renderCallback()}
                    />
                </div>
            }
            </>
        );
    }
}