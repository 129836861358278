import React, { Component } from "react";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

export default class HistoryGraphs extends Component {

    static myPageNumber = 6;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const data = this.props.data.get("streamingHistory");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const dayOfWeekOptions = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            title: null,
            xAxis: {
                categories: [
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag',
                    'Sonntag'
                ],
                crosshair: false,
                style: {
                    color: '#FFFFFF',
                    fontSize: 14,
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Anzahl der wiedergegebenen Titel',
                color: '#F7A35C',
                data: data.timebyweekday

            }],
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        const hourOfDayOptions = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
            },
            title: null,
            xAxis: {
                categories: new Array(24).fill(0).map((_, i) => `${i}:00 - ${i + 1}:00`),
                crosshair: false,
                style: {
                    color: '#FFFFFF',
                    fontSize: 14,
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Anzahl der wiedergegebenen Titel',
                color: '#F15C80',
                data: data.timebyhourofday

            }],
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        const byDayOptions = {
            title: null,
            chart: {
                backgroundColor: 'transparent',
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            series: [{
                name: 'Wiedergabeminuten pro Tag',
                data: data.timedaybyday,
                color: '#60B4EC',
            }],
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        return (
            <>
                <h1>Wann hörst du Musik?</h1>
                <p className={"info-text"}>
                    Eine wichtige Frage ist auch, wann du Spotify zur Medienwiedergabe nutzt. Hier sind einmal alle
                    Streams aus den Daten zusammengefasst. In der ersten Grafik sind die Streams dem Wochentag
                    zugeordnet, an dem der Stream gestartet wurde.
                </p>
                <div className={"chartarea"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={dayOfWeekOptions}
                    />
                </div>
                <p className={"info-text"}>
                    In der zweiten Grafik siehst du eine Zusammenfassung, zu welchem Uhrzeitbereich die Streams
                    gestartet wurden. Erkennst du deine Schlafzeiten?
                </p>
                <div className={"chartarea"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={hourOfDayOptions}
                    />
                </div>
                <p className={"info-text"}>
                    In der dritten Grafik ist einmal die Wiedergabedauer pro Tag in Minuten aus den Daten
                    zusammengefasst.
                </p>
                <div className={"chartarea"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={byDayOptions}
                    />
                </div>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ HistoryGraphs.myPageNumber - 1 }
                    next={ HistoryGraphs.myPageNumber + 1 }
                />
            </>
        );
    }
}
