import React, { Component } from "react";

import Table from "react-bootstrap/Table";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AlertDismissible from "../components/AlertDismissible";
import Navigator from "../components/Navigator";

require("highcharts/modules/wordcloud")(Highcharts);

export default class Inferences extends Component {

    static myPageNumber = 2;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const refData = this.props.data.get("inferences");

        if (refData == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const data = refData.tagCloud;

        const tagcloudOptions = {
            chart: {
                backgroundColor:'transparent',
                height: "50%"
            },
            title: {
                text: ''
            },
            series: [{
                type: 'wordcloud',
                data,
                minFontSize: 60,
                maxFontSize: 70,
                enableMouseTracking: false,
                spiral: 'archimedean'
            }]
        };

        const table = [];
        for (let i = 0; i < refData.inferences.length; i += 2) {
            let chunk = refData.inferences.slice(i, i + 2);
            table.push(
                <tr key={i}>
                    <td>{chunk[0]}</td>
                    <td>{chunk.length >= 2 ? chunk[1] : ""}</td>
                    <td>{chunk.length >= 3 ? chunk[2] : ""}</td>
                </tr>
            )
        }

        return (
            <>
                <h1>Schlussfolgerungen</h1>
                <p className={"info-text"}>
                    Spotify interessiert sich für dich, nicht nur um die passende Musik vorzuschlagen, sondern
                    auch um dir irgendwann passende Werbung zu schalten. Aus diesem Grund versucht Spotify
                    Schlussfolgerungen über dich zu erstellen. Spotify gibt leider nicht an, wie diese
                    Schlussfolgerungen zustande kommen, man kann sich aber denken, was alles mit reinspielt: Das
                    persönliche Nutzungsverhalten auf der Plattform, Interaktion mit Werbung oder eben auch Daten,
                    die Spotify über Cookies oder Verträge von Dritten bekommt.
                </p>
                {data.length > 5 &&
                    <>
                        <p className={"info-text"}>
                            In dieser Wolke von Begriffen ist einmal dargestellt, wer du nach Spotifys
                            Schlussfolgerungen bist. Beachte, dass hier nur ein paar bekannte Begriffe
                            dargestellt werden.
                        </p>
                        <div className={"tagcloud"}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={tagcloudOptions}
                        />
                        </div>
                    </>
                }
                <p className={"info-text"}>
                    Alle Schlussfolgerungen über dich sind einmal in dieser Tabelle aufgelistet. Diese sind direkt aus
                    den Daten entnommen und nur weniger technisch dargestellt, deshalb lesen sich die Begriffe ein
                    bisschen komisch und sind nicht übersetzt:
                </p>
                <Table>
                    <thead></thead>
                    <tbody>
                    { table }
                    </tbody>
                </Table>
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ Inferences.myPageNumber - 1 }
                    next={ Inferences.myPageNumber + 1 }
                />
            </>
        );
    }
}
