import React, { PureComponent } from "react";

import Alert from "react-bootstrap/Alert";

export default class AlertDismissible extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: true
        }
    }

    render() {
        if (!this.state.show) return <></>

        return (
            <Alert variant="danger" onClose={() => this.setState({show: false})} dismissible>
                <Alert.Heading>{this.props.title}</Alert.Heading>
                <p>{this.props.children}</p>
            </Alert>
        );
    }

}