import GenericAnalyzer from "./GenericAnalyzer";

export default class InferenceAnalyzer extends GenericAnalyzer {
    readData = async () => {
        let json = await this.readJSONFromZipEntry("Inferences.json");
        if (json == null) return null;

        let result = {
            tagCloud: [],
            inferences: []
        };

        const inference = new Map([
            ["Auto_Opel", "Opelfahrer"],
            ["Auto_Audi", "Audifahrer"],
            ["Auto_Volkswagen", "Volkswagenfahrer"],
            ["Christmas_Holiday_Playlist_Streamers", "Weihnachtsplaylisthörer"],
            ["Disney_Marvel_Playlist_Streamers", "Disney- und Marvelplaylisthörer"],
            ["Romance Movie Enthusiasts", "Liebesfilm Enthusiast"],
            ["Soccer Fans", "Fussballfan"],
            ["Google_Pixel", "Google Pixel User"],
            ["Social_Gamers", "Spielt gerne soziale Spiele"],
            ["In-Car Streamers", "Musik im Auto hören"],
            ["Samsung_nonSamsungTablet", "Samsung Handy, kein Tablet"],
            ["T-Mobile", "T-Mobile Nutzer"],
            ["Wine Drinkers", "Weintrinker"],
            ["Vodafone", "Vodafone Nutzer"],
            ["Android Users", "Android Nutzer"],
            ["Alcohol Consumers", "Alkoholtrinker"],
            ["Beer Consumers", "Biertrinker"],
            ["Apple iOS Users", "Apple iOS Nutzer"],
            ["College Students", "Student"],
            ["Social Media Enthusiasts", "Soziale Medien"],
            ["Couple without Kids", "Paar ohne Kinder"],
            ["Role Playing Gamers", "Rollenspiele"],
            ["Travelers", "Reisender"],
            ["Discount Shoppers", "Einkaufen im Discounter"],
            ["Need-It-All", "Braucht alles"],
            ["No Time",  "Hat wenig Zeit"],
            ["Budget Holidayers", "Preiswerter Urlaub"],
            ["Business Travelers", "Geschäftsreisender"],
            ["Used Car Buyers", "Gebrauchtwagenkäufer"],
            ["Streaming TV Users", "Streamt am TV"],
            ["Car Buyers", "Autokäufer"],
            ["Car Owners", "Autobesitzer"],
            ["Car Enthusiasts", "Auto Enthusiast"],
            ["Children_Toys", "Kinderspielzeug"],
            ["Cinema Goers", "Kinobesucher"],
            ["Credit Applicants", "Bewerbung um einen Kredit"],
            ["Amazon Prime Subscribers", "Amazon Prime Abonnent"],
            ["AmazonBuyers", "Amazon Käufer"],
            ["Green Living", "Interesse an Grünem Leben"],
            ["Paid Video Streaming Services", "Nutzt Video Streaming"],
            ["Online Purchase", "Interesse am Onlinekauf"],
            ["Graduates", "Gebildet"],
            ["Degree Educated", "Gebildet"],
            ["Engineer", "Ingenieur"],
            ["Haircare Category Buyers", "Käufer von Haarpflegeprodukten"],
            ["Health and Wellness", "Gesundheit und Wellness"],
            ["Healthy Living", "Gesundes Leben"],
            ["Professional Sports Fans", "Profisportfan"],
            ["Fast Food", "Fast Food"],
            ["DVD Buyers", "DVD Käufer"],
            ["Custom_Team Leaders", "Teamleiter"],
            ["Head of Household", "Haushaltsvorstand"],
            ["Grocery Buyers", "Supermarkteinkäufer"],
            ["IT Professionals", "IT Professional"],
            ["Laptop Purchase Intenders", "Interesse an einem Laptopkauf"],
            ["Smartphones", "Smartphone Nutzer"],
            ["Fitness Enthusiasts", "Fitness Enthusiast"],
            ["Tech Early Adopters", "Early Adopter"],
            ["Foodies", "Essensliebhaber"],
            ["Fitness", "Fitness"],
            ["Partying", "Partygänger"],
            ["Running", "Läufer"],
            ["Tech Enthusiasts", "Technik Enthusiast"],
            ["Anime_Manga_Enthusiast", "Anime und Manga Enthusiast"],
            ["Horror Movie Enthusiasts", "Horror Film Enthusiast"],
            ["Luxury Car Buyers", "Käufer von Luxus Autos"],
            ["Gaming", "Gaming"],
            ["Home Owners", "Hausbesitzer"],
            ["Home Renters", "Mieter von einem Haus"],
            ["In-Car Listening", "Musik im Auto hören"],
            ["International Travelers", "Internationale Reisen"],
            ["Parenting", "Kinderbetreuung"],
            ["Motorsport Enthusiasts", "Motorsport Enthusiast"],
            ["Outdoor Enthusiasts", "Outdoor Enthusiast"],
            ["Pet Owners", "Haustierbesitzer"],
            ["Parents of Boys", "Eltern mit Jungen"],
            ["Parents of Girls", "Eltern mit Mädchen"],
            ["Parents of Kids", "Eltern mit Kindern"],
            ["Studying", "Lernen"],
            ["focusing", "Konzentrieren"],
            ["podcast-audience-segmentation-rules-content-personal-science", "Podcasts Personal Science"],
            ["podcast-audience-segmentation-rules-content-true-crime", "Podcast True Crime"],
            ["podcast-audience-segmentation-rules-engagement-daypart-late-evening", "Podcasts spät abends hören"],
            ["podcast-audience-segmentation-rules-engagement-daypart-evening", "Podcasts abends hören"],
            ["podcast-audience-segmentation-rules-engagement-daypart-morning", "Podcasts morgens hören"],
            ["podcast-audience-segmentation-rules-engagement-device-desktop", "Podcasts auf Desktop hören"],
            ["podcast-audience-segmentation-rules-engagement-device-mobile", "Podcasts auf Mobilgerät hören"],
            ["podcast-audience-segmentation-rules-format-length-medium", "Hört gerne mittellange Podcasts"],
            ["podcast-audience-segmentation-rules-format-length-long", "Hört gerne lange Podcasts"],
            ["podcast-audience-segmentation-rules-format-length-short", "Hört gerne kurze Podcasts"]
        ]);

        for (let [key, value] of inference) {
            for (let entry of json.inferences) {
                if (entry.includes(key)) {
                    result.tagCloud.push({
                        name: value,
                        weight: entry.startsWith("3") ? 3 : 1
                    });
                    break;
                }
            }
        }

        for (let entry of json.inferences) {
            let split = entry.split("_");

            if (split.length === 1) result.inferences.push(entry);

            if (split.length > 1 && (split[0].includes("P") || split[0].includes("p"))) split.shift();
            if (split.length > 1 && split[0].includes("Custom")) split.shift();
            if (split.length > 1 && split[0] === "") split.shift();
            if (split.length > 1 && split[split.length - 1].length === 2) split.pop();

           result.inferences.push(split.join(" "));
        }

        result.inferences = [...new Set(result.inferences)];

        return result;
    }
}