import React, { Component } from "react";

import Highcharts from 'highcharts';

import AlertDismissible from "../components/AlertDismissible";
import LoadingChart from "../components/LoadingChart";
import Navigator from "../components/Navigator";

export default class FavoritesGraphs extends Component {

    static myPageNumber = 5;

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {

        const data = this.props.data.get("streamingHistory");

        if (data == null) {
            return (
                <AlertDismissible title={"Da gab es wohl ein Problem :/"}>
                    Diese Daten konnten nicht aufbereitet werden. Lade bitte die Seite neu.
                </AlertDismissible>);
        }

        const artistsFromTime = {
            chart: {
                type: 'area',
                backgroundColor: 'transparent',
                height: '45%',
                zoomType: 'x'
            },
            title: null,
            xAxis: {
                categories:  data.top20artistsListenTime.categories,
                tickmarkPlacement: 'on',
                tickInterval: 5,
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            yAxis: {
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            tooltip: {
                split: true,
                valueSuffix: ' Minuten'
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            series: data.top20artistsListenTime.series,
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        const titlesFromTime = {
            chart: {
                type: 'area',
                backgroundColor: 'transparent',
                height: '50%',
                zoomType: 'x'
            },
            title: null,
            xAxis: {
                categories:  data.top20titlesListenCount.categories,
                tickmarkPlacement: 'on',
                tickInterval: 5,
                title: {
                    enabled: false
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            yAxis: {
                title: null,
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: 14,
                    }
                }
            },
            tooltip: {
                split: true,
                valueSuffix: ' Minuten'
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            series: data.top20titlesListenCount.series,
            legend: {
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: 14
                }
            }
        };

        return (
            <>
                <h1>Wann hörst du eigentlich deine Lieblingsmusik?</h1>
                <p className={"info-text"}>
                    Auf der vorherigen Seite hast du deine Top 20 Künstler kennen gelernt. In deinem Streamingverlauf
                    lässt sich auch herausfinden, wann du welchen deiner Lieblingskünstler besonders viel gehört hast.
                    Hier erfährst du, wie viele Minuten du pro Woche die Titel deiner 20 Lieblingskünstler gehört hast.
                    Du kannst mit der Maus einen Bereich der Grafik auswählen, um diesen größer dargestellt zu bekommen.
                </p>
                <LoadingChart
                    name={"Grafik"}
                    highcharts={Highcharts}
                    options={artistsFromTime}
                />
                <p className={"info-text"}>
                    Die selbe Grafik lässt sich auch mit deinen Lieblingstiteln erstellen: Hier erfährst du, wie viele
                    Minuten du pro Woche deine 20 Lieblingstitel gehört hast. Auch hier kannst du mit der Maus einen
                    Bereich der Grafik auswählen, um diesen größer zu sehen.
                </p>
                <LoadingChart
                    name={"Grafik"}
                    highcharts={Highcharts}
                    options={titlesFromTime}
                />
                <Navigator
                    nextToolTip="Nächste Seite"
                    prevToolTip="Eine Seite zurück"
                    pageSelector={ this.props.pageSelector }
                    prev={ FavoritesGraphs.myPageNumber - 1 }
                    next={ FavoritesGraphs.myPageNumber + 1 }
                />
            </>
        );
    }
}
