import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";

import AlertDismissible from "../components/AlertDismissible";
import DataProvider from "../utils/DataProvider";

import "./Upload.css";
import Navigator from "../components/Navigator";

export const uploadProgress = Object.freeze({
    notStarted: 1,
    inProgress: 2,
    completed: 3,
    error: 4
});

export default class Upload extends Component {

    static myPageNumber = 0;

    file = null;
    dataProvider = null;

    constructor(props) {
        super(props);

        this.dataProvider = new DataProvider(this.externalProgressCallback);

        this.state = {
            error: null,
            btnOff: true,
            selectOff: false,
            stepText: null,
            uploadProgress: uploadProgress.notStarted,
            progressPercent: 0
        }
    }

    externalProgressCallback = (progress, message, percent) => {
        if (progress === uploadProgress.error) {
            this.setState({
                uploadProgress: progress,
                error: message,
                stepText: null,
                progressPercent: 0,
                btnOff: false,
                selectOff: false
            });
            return;
        } else if (progress === uploadProgress.completed) {
            this.props.submitData(this.dataProvider.getPageData());
        }
        this.setState({
            uploadProgress: progress,
            error: null,
            stepText: message,
            progressPercent: percent
        });
    }

    fileChangeCallback = (event) => {
        if (event.target.files.length === 0) return;

        this.file = event.target.files[0];
        this.setState({
            error: null,
            btnOff: false
        });
    }

    startAnalysisCallback =  async (event) => {
        if (this.file === null) return;
        this.setState({
            error: null,
            uploadProgress: uploadProgress.inProgress,
            selectOff: true,
            btnOff: true
        });

        this.dataProvider.resetCurrentState();
        await this.dataProvider.parseData(true, this.file);
    }

    loadDemoData = async (event) => {
        if (this.state.selectOff) return;
        this.setState({
            error: null,
            uploadProgress: uploadProgress.inProgress,
            selectOff: true,
            btnOff: true
        });

        this.dataProvider.resetCurrentState();
        await this.dataProvider.parseData(false, "/sample-data.zip");
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <>
                <h1>Öffne hier deine Spotify-Daten</h1>
                <p className={"info-text"}>
                    Wenn du deine Daten von Spotify per Mail erhalten hast, kannst du diese auf dieser Seite öffnen.
                    <br />
                    Nochmals der Hinweis: Die Daten verbleiben zur Analyse in deinem Browser und werden weder auf
                    unseren noch auf fremde Server hochgeladen.
                </p>
                <Container className={"spacer"}>
                    <h5>
                        Öffne hier die .zip-Datei, die du von Spotify erhalten hast. Bitte die Datei vor dem Öffnen
                        nicht modifizieren oder entpacken.
                    </h5>
                    <Row>
                        <Col md={4}>
                            <Form>
                                <Form.Control
                                    type="file"
                                    placeholder="Datei öffnen"
                                    onChange={this.fileChangeCallback}
                                    disabled={this.state.selectOff}
                                    accept=".zip"
                                />
                            </Form>
                        </Col>
                        <Col>
                            <Button
                                variant={"success"}
                                disabled={this.state.btnOff}
                                onClick={this.startAnalysisCallback}>
                                Analyse starten
                            </Button>
                        </Col>
                    </Row>
                    <small>
                        Solltest du deine Daten (noch) nicht haben, kannst du dir diese Seite mit Beispieldaten
                        anschauen. Klicke dafür <span className={"demobutton " + (this.state.selectOff ? "db-disabled" : "")} onClick={this.loadDemoData}>hier</span>.
                    </small>
                    { this.state.uploadProgress === uploadProgress.error &&
                        <AlertDismissible title={"Hoppla, da gab es einen Fehler beim Verarbeiten der Datei!"}>
                            { this.state.error === null ? "Unbekannter Fehler" : this.state.error }
                        </AlertDismissible>
                    }
                </Container>
                { this.state.uploadProgress === uploadProgress.inProgress &&
                    <>
                        <hr />
                        <h3>Deine Daten werden verarbeitet, bitte habe einen Moment Geduld ...</h3>
                    </>
                }
                { this.state.uploadProgress === uploadProgress.completed &&
                    <>
                        <hr />
                        <h3>Deine Daten wurden analysiert, es kann losgehen!</h3>
                    </>
                }
                { (this.state.uploadProgress === uploadProgress.inProgress
                        || this.state.uploadProgress === uploadProgress.completed) &&
                    <ProgressBar variant={"success"} animated label now={this.state.progressPercent} />
                }
                { this.state.uploadProgress === uploadProgress.inProgress &&
                    <h5>Aktueller Schritt: {this.state.stepText === null ? "Unbekannt" : this.state.stepText}</h5>
                }
                { this.state.uploadProgress === uploadProgress.completed &&
                    <>
                        <p className={"info-text"}>
                            Du kannst jetzt mit den Schaltflächen links und rechts unten am Bildschirm zwischen den
                            verschiedenen Seiten hin und her navigieren.
                        </p>
                        <Button variant={"success"} size={"lg"} onClick={() => this.props.pageSelector("1")}>
                            Schau dir deine Daten an
                        </Button>
                    </>
                }
                <Navigator
                    nextToolTip="Schau dir deine Daten an!"
                    prevToolTip="Zurück zur Übersicht"
                    pageSelector={this.props.pageSelector}
                    prev={ this.state.uploadProgress !== uploadProgress.inProgress ? "welcome" : undefined }
                    next={ this.state.uploadProgress === uploadProgress.completed ? Upload.myPageNumber + 1 : undefined }
                />
            </>
        );
    }
}