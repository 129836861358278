import * as zip from "@zip.js/zip.js";

import { uploadProgress } from "../pages/Upload";
import UserDataAnalyzer from "./UserDataAnalyzer";
import InferenceAnalyzer from "./InferenceAnalyzer";
import StreamingHistoryAnalyzer from "./StreamingHistoryAnalyzer";
import SearchQueriesAnalyzer from "./SearchQueriesAnalyzer";

export default class DataProvider {
    static neededFiles = [
        "Userdata.json",
        "Inferences.json"
    ];

    stateCallback;
    pageDataMap = new Map();

    constructor(stateCallback) {
        this.stateCallback = stateCallback;
    }

    getPageData = () => {
        return this.pageDataMap;
    }

    resetCurrentState = () => {
        this.pageDataMap = new Map();
    }

    parseData = async (isfile, file) => {
        let entries, reader;
        if (isfile) {
            this.stateCallback(uploadProgress.inProgress, "Öffne Datei", 0);
            let byteArray;
            try {
                byteArray = new Uint8Array(await file.arrayBuffer());
            } catch (e) {
                this.stateCallback(uploadProgress.error, "Kann die gewählte Datei nicht öffnen", 0);
                return;
            }

            this.stateCallback(uploadProgress.inProgress, "Öffne Zip-Archiv", 0);
            try {
                reader = new zip.ZipReader(new zip.Uint8ArrayReader(byteArray));
                entries = await reader.getEntries();
            } catch (e) {
                this.stateCallback(uploadProgress.error, "Kann die Zip-Datei nicht öffnen, richtige Datei ausgewählt?", 0);
                return;
            }
        } else {
            this.stateCallback(uploadProgress.inProgress, "Lade Zip-Archiv herunter und öffne ...", 0);
            try {
                reader = new zip.ZipReader(new zip.HttpReader(file));
                entries = await reader.getEntries();
            } catch (e) {
                this.stateCallback(uploadProgress.error, "Kann Beispieldaten nicht öffnen", 0);
                return;
            }
        }


        const filemap = new Map();

        for (let entry of entries) {
            let entrysplit = entry.filename.split('/');
            let entryname = entrysplit[entrysplit.length - 1];
            filemap.set(entryname, entry);
        }

        for (let test of DataProvider.neededFiles) {
            if (filemap.has(test)) continue;

            this.stateCallback(uploadProgress.error, "Folgende Datei fehlt in dem Zip-Archiv: " + test, 0);
            return;
        }

        // Page 1: Userdata Overview
        this.stateCallback(uploadProgress.inProgress, "Verarbeite die Datei userdata.json", 10);
        let userDataResult = await this.#getDataSave(new UserDataAnalyzer(filemap));
        if (userDataResult == null) {
            this.stateCallback(uploadProgress.error, "Fehler beim Verarbeiten der Datei userdata.json", 10);
            return;
        } else {
            this.pageDataMap.set("overview", userDataResult);
        }

        // Page 2: Inferences
        this.stateCallback(uploadProgress.inProgress, "Verarbeite die Datei Inferences.json", 20);
        let inferencesResult = await this.#getDataSave(new InferenceAnalyzer(filemap));
        if (inferencesResult == null) {
            this.stateCallback(uploadProgress.error, "Fehler beim Verarbeiten der Datei Inferences.json", 20);
            return;
        } else {
            this.pageDataMap.set("inferences", inferencesResult);
        }

        // Page 3 + 4 + 5 + 6: Top Artists per Hour, Top Titles per Hour, Graphs, Heatmap
        this.stateCallback(uploadProgress.inProgress, "Verarbeite den Streamingverlauf (StreamingHistoryN.json)", 40);
        let streamingHistoryResult = await this.#getDataSave(new StreamingHistoryAnalyzer(filemap));
        if (streamingHistoryResult == null) {
            this.stateCallback(uploadProgress.error,
                "Fehler beim Verarbeiten des Streamingverlaufs (StreamingHistoryN.json)", 40);
            return;
        } else {
            this.pageDataMap.set("streamingHistory", streamingHistoryResult);
        }

        // Page 7: Search Query Tag Cloud
        this.stateCallback(uploadProgress.inProgress, "Verarbeite die Datei SearchQueries.json", 80);
        let searchQueriesResult = await this.#getDataSave(new SearchQueriesAnalyzer(filemap));
        if (searchQueriesResult == null) {
            this.stateCallback(uploadProgress.error,
                "Fehler beim Verarbeiten der Datei SearchQueries.json", 80);
            return;
        } else {
            this.pageDataMap.set("searchQueries", searchQueriesResult);
        }

        try {
            reader.close();
        } catch (e) {
            this.stateCallback(uploadProgress.error, "Kann die Zip-Programm nicht beenden.", 0);
            return;
        }

        this.stateCallback(uploadProgress.completed, "", 100);
    }

    #getDataSave = async (obj) => {
        let result;
        try {
            result = await obj.readData();
        } catch (e) {
            console.error(e);
            return null;
        }

        return result;
    }
}