import React, { PureComponent } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { ArrowLeftSquare, ArrowRightSquare, PlusSquare } from "react-bootstrap-icons";

export default class Navigator extends PureComponent {
    getArrow = () => {
        if (this.props.restart === undefined) {
            return (
                <ArrowRightSquare
                    color="white"
                    size={96}
                    onClick={() => this.props.pageSelector(this.props.next)}/>
            );
        } else {
            return (
                <PlusSquare
                    color="white"
                    size={96}
                    onClick={() => this.props.pageSelector(this.props.next)}/>
            );
        }
    }

    render() {
        return (
            <>
            <div className={"footroom"}></div>
            <div className={"fixed-bottom"}>
                { this.props.prev !== undefined &&
                    <div className={"float-start arrow"}>
                        <OverlayTrigger
                            key={'right'}
                            placement='right'
                            overlay={<Tooltip>{ this.props.prevToolTip }</Tooltip>}>
                            <ArrowLeftSquare
                                color="white"
                                size={96}
                                onClick={() => this.props.pageSelector(this.props.prev)}/>
                        </OverlayTrigger>
                    </div>
                }
                { this.props.next !== undefined &&
                    <div className={"float-end arrow"}>
                        <OverlayTrigger
                            key={'left'}
                            placement='left'
                            overlay={<Tooltip>{ this.props.nextToolTip }</Tooltip>}>
                            { this.getArrow() }
                        </OverlayTrigger>
                    </div>
                }
            </div>
            </>
        );
    }
}