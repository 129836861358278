import GenericAnalyzer from "./GenericAnalyzer";

export default class StreamingHistoryAnalyzer extends GenericAnalyzer {

    #getDate = (dateString) => {
        return new Date(dateString.substring(0, 4), dateString.substring(5, 7) - 1, dateString.substring(8, 10), dateString.substring(11, 13), dateString.substring(14, 16));
    }

    readData = async () => {
        let jsons = [];
        for (let i = 0; ; i++) {
            if (!this.isFileInMap("StreamingHistory" + i + ".json")) break;

            let json = await this.readJSONFromZipEntry("StreamingHistory" + i + ".json");
            if (json == null) break;

            jsons.push(json)
        }

        if (jsons.length === 0) return null;

        let result = {
            topartistsperhour: new Map(),
            toptitlesperhour: new Map(),
            toptitles: new Map(),
            topartists: new Map(),
            timebyweekday: new Array(7).fill(0),
            timebyhourofday: new Array(24).fill(0),
            timedaybyday: [],
            heatmap: {
                minStamp: Infinity,
                maxStamp: 0,
                min: "",
                max: "",
                csv: ""
            },
            top20artistsListenTime: {
                categories: [],
                series: []
            },
            top20titlesListenCount: {
                categories: [],
                series: []
            }
        };

        // Analyze Top Artists / Top Songs per Hour
        for (let i = 0; i < 24; i++) {
            result.topartistsperhour.set(i, new Map());
            result.toptitlesperhour.set(i, new Map());
        }

        for (let json of jsons) {
            for (let entry of json) {
                let time = this.#getDate(entry.endTime);
                time.setTime(time.getTime() - entry.msPlayed);
                
                // Author
                let hourMap = result.topartistsperhour.get(time.getHours());
                let artistCounter = hourMap.get(entry.artistName);
                if (artistCounter == null) {
                    hourMap.set(entry.artistName, 1);
                } else {
                    hourMap.set(entry.artistName, artistCounter + 1);
                }
                
                // Song
                if (entry.trackName === "Unknown Track") continue;
                hourMap = result.toptitlesperhour.get(time.getHours());
                let titleCounter = hourMap.get(entry.trackName);
                if (titleCounter == null) {
                    hourMap.set(entry.trackName, 1);
                } else {
                    hourMap.set(entry.trackName, titleCounter + 1);
                }
            }
        }

        for (let i = 0; i < 24; i++) {
            result.topartistsperhour.set(i, new Map([...result.topartistsperhour.get(i).entries()].sort((a, b) => b[1] - a[1])));
            result.toptitlesperhour.set(i, new Map([...result.toptitlesperhour.get(i).entries()].sort((a, b) => b[1] - a[1])));
        }

        // Analyze Top Titles
        for (let json of jsons) {
            for (let entry of json) {
                if (entry.trackName === "Unknown Track") continue;

                let titleCounter = result.toptitles.get(entry.trackName);
                if (titleCounter == null) {
                    result.toptitles.set(entry.trackName, 1);
                } else {
                    result.toptitles.set(entry.trackName, titleCounter + 1);
                }
            }
        }
        result.toptitles = new Map([...result.toptitles.entries()].sort((a, b) => b[1] - a[1]));

        // Analyze Top Artists
        for (let json of jsons) {
            for (let entry of json) {
                if (entry.trackName === "Unknown Track") continue;

                let authorCounter = result.topartists.get(entry.artistName);
                if (authorCounter == null) {
                    result.topartists.set(entry.artistName, 1);
                } else {
                    result.topartists.set(entry.artistName, authorCounter + 1);
                }
            }
        }
        result.topartists = new Map([...result.topartists.entries()].sort((a, b) => b[1] - a[1]));




        // Top 20 Artists Listen Time + Top 20 Titles Play Count - Stack Area Chart
        let artistPlaytimeMap = new Map();
        let titlesPlaycountMap = new Map();
        let topArtistIterator = result.topartists.entries();
        let topTitlesIterator = result.toptitles.entries();
        let artistTimeAgg = new Map();
        let titlesPlayAgg = new Map();

        for (let i = 0; i < 20; i++) {
            let topArtist = topArtistIterator.next();
            let topTitle = topTitlesIterator.next();

            result.top20artistsListenTime.series.push({
                name: topArtist.value[0],
                data: []
            });
            result.top20titlesListenCount.series.push({
                name: topTitle.value[0],
                data: []
            });
            artistTimeAgg.set(topArtist.value[0], 0);
            titlesPlayAgg.set(topTitle.value[0], 0);

            for (let json of jsons) {
                for (let entry of json) {
                    let time = this.#getDate(entry.endTime);
                    time.setTime(time.getTime() - entry.msPlayed);
                    time.setMilliseconds(0);
                    time.setSeconds(0);
                    time.setMinutes(0);
                    time.setHours(0);
                    time.setDate(time.getDate() - (time.getDay() - 1));

                    if (entry.artistName === topArtist.value[0]) {
                        let day = artistPlaytimeMap.get(time.getTime());
                        if (day == null) {
                            let artistTime = new Map();
                            artistTime.set(topArtist.value[0], Math.floor(entry.msPlayed / (1_000 * 60)));
                            artistPlaytimeMap.set(time.getTime(), artistTime);
                        } else {
                            let time = day.get(topArtist.value[0]);
                            if (time == null) {
                                time = Math.floor(entry.msPlayed / (1_000 * 60));
                            } else {
                                time += Math.floor(entry.msPlayed / (1_000 * 60));
                            }
                            day.set(topArtist.value[0], time);
                        }
                    }

                    if (entry.trackName === topTitle.value[0]) {
                        let day = titlesPlaycountMap.get(time.getTime());
                        if (day == null) {
                            let titleCount = new Map();
                            titleCount.set(topTitle.value[0], 1);
                            titlesPlaycountMap.set(time.getTime(), titleCount);
                        } else {
                            let counter = day.get(topTitle.value[0]);
                            if (counter == null) {
                                counter = 1;
                            } else {
                                counter += 1;
                            }
                            day.set(topTitle.value[0], counter);
                        }
                    }
                }
            }
        }

        for (let day of [...artistPlaytimeMap.entries()].sort()) {
            result.top20artistsListenTime.categories.push((new Date(day[0])).toISOString().slice(0,10));
            for (let artistData of result.top20artistsListenTime.series) {
                let artistTimeData = day[1].get(artistData.name);
                let artistTimeAggTmp = artistTimeAgg.get(artistData.name);
                if (artistTimeData == null) {
                    artistData.data.push(/*artistTimeAggTmp*/ 0); // uncomment artistTimeAggTmp if you want aggregated results
                } else {
                    artistData.data.push(artistTimeData /*+ artistTimeAggTmp*/); // uncomment artistTimeAggTmp if you want aggregated results
                    artistTimeAgg.set(artistData.name, artistTimeData + artistTimeAggTmp);
                }
            }
        }

        for (let day of [...titlesPlaycountMap.entries()].sort()) {
            result.top20titlesListenCount.categories.push((new Date(day[0])).toISOString().slice(0,10));
            for (let titleData of result.top20titlesListenCount.series) {
                let titleCountData = day[1].get(titleData.name);
                let titleCountAggTmp = titlesPlayAgg.get(titleData.name);
                if (titleCountData == null) {
                    titleData.data.push(/*titleCountAggTmp*/ 0); // uncomment titleCountAggTmp if you want aggregated results
                } else {
                    titleData.data.push(titleCountData /*+ titleCountAggTmp*/); // uncomment titleCountAggTmp if you want aggregated results
                    titlesPlayAgg.set(titleData.name, titleCountData + titleCountAggTmp);
                }
            }
        }


        let playTimeByDayMap = new Map();

        // Analyze Playtimes - Heatmap
        let heatmapData = new Map();
        for (let json of jsons) {
            for (let entry of json) {
                let endTime = this.#getDate(entry.endTime);
                let startTime = new Date(endTime.getTime() - entry.msPlayed);

                // Analyze Day of Week, Hour of Day, Streaming time per Day
                result.timebyweekday[(startTime.getDay() + 1) % 7]++;
                result.timebyhourofday[startTime.getHours()]++;

                let startDate = startTime.toISOString().slice(0,10);
                let lastVal = playTimeByDayMap.get(startDate);
                if (lastVal !== undefined) {
                    playTimeByDayMap.set(startDate, lastVal + entry.msPlayed);
                } else  {
                    playTimeByDayMap.set(startDate, entry.msPlayed);
                }


                let oldStartTime;
                let remainingMsInCurrentHour;
                
                do {
                    oldStartTime = startTime;
                    if (startTime.getHours() === endTime.getHours()) {
                        remainingMsInCurrentHour = endTime.getTime() - startTime.getTime();
                    } else {
                        let nextHour = new Date(startTime.getTime())
                        nextHour.setHours(nextHour.getHours() + 1); // this works for 23 --> automatic overflow handling
                        nextHour.setMinutes(0);
                        nextHour.setSeconds(0);
                        nextHour.setMilliseconds(0);
                        remainingMsInCurrentHour = nextHour.getTime() - startTime.getTime();
                        startTime = nextHour;
                    }

                    let normalizedStartTime = Math.floor(startTime.getTime() / 3_600_000) * 3_600_000;
                    let hourlyListenTime = heatmapData.get(normalizedStartTime);
                    if (hourlyListenTime == null) {
                        heatmapData.set(normalizedStartTime, Math.floor(remainingMsInCurrentHour / 1_000));
                    } else {
                        let aggregatedTime = hourlyListenTime + Math.floor(remainingMsInCurrentHour / 1_000);
                        if (aggregatedTime > 3600) aggregatedTime = 3600;
                        heatmapData.set(normalizedStartTime,  aggregatedTime);
                    }
                } while (oldStartTime.getHours() !== endTime.getHours());
            }
        }

        result.heatmap.csv = "Date,Time,SecondsPlayback\n";
        for (let entry of heatmapData.entries()) {
            let entryDate = new Date(entry[0]);
            
            if (entry[0] < result.heatmap.minStamp) {
                result.heatmap.minStamp = entry[0];
                result.heatmap.min = entryDate;
            }

            if (entry[0] > result.heatmap.maxStamp) {
                result.heatmap.maxStamp = entry[0];
                result.heatmap.max = entryDate;
            }

            result.heatmap.csv += entryDate.toISOString().slice(0,10);
            result.heatmap.csv += "," + entryDate.getHours() + "," + Math.floor(entry[1] / 60) + "\n";
        }

        playTimeByDayMap = new Map([...playTimeByDayMap.entries()]
            .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()));
        for (let [at, msPlayed] of playTimeByDayMap.entries()) {
            result.timedaybyday.push([new Date(at).getTime(), Math.floor(msPlayed / (60 * 1_000))]);
        }

        return result;
    }
}